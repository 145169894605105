<template>
  <div class="page">
    <section v-if="detail">
      <span class="title">调研规则</span>
      <div class="content">
        <img :src="detail.image" alt="" />
        <span class="text" v-html="detail.content"></span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    // 详情
    async getDetail() {
      this.xin.isLoadding(true);
      let res = await this.$researchApi.questionnaireRule();
      if (res.code == 200) {
        this.detail = res.data || [];
      }
      this.xin.isLoadding();
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

.page {
  > section {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #333333;
    > .title {
      font-weight: bold;
      margin: 30px 0 42px 0;
    }
    > .content {
      width: 70%;
      > img {
        width: 100%;
        object-fit: cover;
      }
      > .subTitle {
        font-size: 24px;
        margin-bottom: 20px;
      }
      > .text {
        min-height: 300px;
        line-height: 1.8;
        /* overflow: hidden; */
		/* overflow: auto; */
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        font-size: 16px;
        margin-top: 30px;
      }
    }
    > .button {
      width: 384px;
      height: 46px;
      background: $--themeColor;
      border-radius: 23px;
      font-size: 15px;
      color: #ffffff;
      line-height: 46px;
      text-align: center;
      margin: 0 auto;
      margin-top: 40px;
    }
  }
}
</style>